import Slider from "./slider";
import About from "./about";
import WhatWeDo from "./whatWeDo";
import WhatWeExport from "./whatWeExport";
import Client from "./client";
import Certificates from "./certificates";
// import Gallery from "./gallery";
import WhyYouChooseUs from "./whyYouChooseUs";
// import LoveFromClient from "./loveFromClient";

const Home = () => (
  <>
    <Slider />
    <About />
    <WhatWeDo />
    <WhatWeExport />
    {/* <Gallery />  */}
    <WhyYouChooseUs />
    <Certificates />
    <Client />
  </>
);
export default Home;
