import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/home";
import Contact from "./pages/contact";
import Gallery from "./pages/gallery";
import Company from "./pages/about/company";
import Certification from "./pages/about/certification";
import KeyPerson from "./pages/about/keyPerson";
import SourcingAgent from "./pages/sourcingAgent";
import Products from "./pages/products";
import "./App.css";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  { path: "/home", element: <Home /> },
  {
    path: "/company",
    element: <Company />,
  },
  {
    path: "/certification",
    element: <Certification />,
  },
  {
    path: "/key-person",
    element: <KeyPerson />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/gallery",
    element: <Gallery />,
  },
  {
    path: "/sourcing-agent",
    element: <SourcingAgent />,
  },
  {
    path: "/products/:param1?/:param2?",
    element: <Products />,
  },
];

const router = createBrowserRouter(
  routes.map((item) => ({
    path: item.path,
    element: (
      <>
        <Header />
        {item.element}
        <Footer />
      </>
    ),
    errorBoundary: <h1> 404 </h1>,
  }))
  // [
  //   {
  //     path: "/home",
  //     element: <h1> Hello World </h1>,
  //   },
  // ]
);

const App = () => <RouterProvider router={router} />;

export default App;
