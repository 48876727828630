import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import facebook from "../../assets/img/social/facebook.png";
import x from "../../assets/img/social/x.png";
import linkedin from "../../assets/img/social/linkedin.png";
import instagram from "../../assets/img/social/instagram.png";

const socialImages = [facebook, x, linkedin, instagram];

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_h96d87g", "template_lr3mmwb", form.current, {
        publicKey: "XIncisSCGc7H0DTep",
      })
      .then(
        () => {
          alert("Form submitted successfully");
          window.location.reload();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <section
      id="contact"
      className="contact section"
      style={{ marginTop: "6rem" }}
    >
      {/* Section Title */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Contact</h2>
        <p>Contact Us</p>
      </div>
      {/* End Section Title */}
      <div className="container" data-aos="fade-up" data-aos-delay={100}>
        <div className="mb-4" data-aos="fade-up" data-aos-delay={200}>
          <iframe
            style={{ border: 0, width: "100%", height: 270 }}
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16015.042903745926!2d79.1027194!3d21.1551596!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c1c1ec352acd%3A0x7d2a813434e25d40!2sShubham%20collection!5e0!3m2!1sen!2sus!4v1693313823621!5m2!1sen!2sus"
            frameBorder="0"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        {/* End Google Maps */}
        <div className="row gy-4">
          <div className="col-lg-4">
            <div
              className="info-item d-flex"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <i className="bi bi-geo-alt flex-shrink-0" />
              <div>
                <h3>Address</h3>
                <p>
                  1st Floor, Everest Plaza, Ganjhakhet, Gandhibagh,
                  Nagpur-440002
                </p>
              </div>
            </div>
            {/* End Info Item */}
            <div
              className="info-item d-flex"
              data-aos="fade-up"
              data-aos-delay={400}
            >
              <i className="bi bi-telephone flex-shrink-0" />
              <div>
                <h3>Call Us</h3>
                <p>+91 8484086082</p>
              </div>
            </div>
            {/* End Info Item */}
            <div
              className="info-item d-flex"
              data-aos="fade-up"
              data-aos-delay={500}
            >
              <i className="bi bi-envelope flex-shrink-0" />
              <div>
                <h3>Email Us</h3>
                <p>info@theoneinfinity.com</p>
              </div>
            </div>
            {/* End Info Item */}
          </div>
          <div className="col-lg-8">
            <form
              ref={form}
              onSubmit={sendEmail}
              className="php-email-form"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="row gy-4">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="from"
                    className="form-control"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="col-md-6 ">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Your Email"
                    required
                  />
                </div>
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="col-md-12">
                  <textarea
                    className="form-control"
                    name="message"
                    rows={6}
                    placeholder="Message"
                    required
                    defaultValue={""}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <div className="loading">Loading</div>
                  <div className="error-message" />
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                  <button type="submit">Send Message</button>
                </div>
              </div>
            </form>
          </div>

          {/* End Contact Form */}

          <div className="container" data-aos="fade-up" data-aos-delay={100}>
            <div className="row gy-4">
              <hr style={{ marginTop: "4rem" }} />
              <h1
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bolder",
                  textAlign: "center",
                }}
              >
                {" "}
                Stay in Connected{" "}
              </h1>
              {socialImages?.map((item) => (
                <div className="col-lg-3 child-img-col" key={item?.title}>
                  <img src={item} alt={item} className="child-img" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
