import WeBuildRelationship from "../../assets/img/Why Choose Us/WE BUILD RELATIONS.png";
import ExperienceAndProfessionals from "../../assets/img/Why Choose Us/Professional.png";
import HighQualityProducts from "../../assets/img/Why Choose Us/HIGH QUALITY PRODUCTS.png";
import WeDeliverOnTime from "../../assets/img/Why Choose Us/WE DELIVER ON TIME.png";
import PriceBenefit from "../../assets/img/Why Choose Us/Price Benefits.png";
import CompleteClientSatisfaction from "../../assets/img/Why Choose Us/client satisfaction.png";

const choose = [
  {
    label: "We Build Relationship",
    img: WeBuildRelationship,
  },
  {
    label: "Experience And Professionals",
    img: ExperienceAndProfessionals,
  },
  {
    label: "High Quality Products",
    img: HighQualityProducts,
  },
  {
    label: "We Deliver On Time",
    img: WeDeliverOnTime,
  },
  {
    label: "Price Benefit",
    img: PriceBenefit,
  },
  {
    label: "Complete Client Satisfaction",
    img: CompleteClientSatisfaction,
  },
];

const whyYouChooseUs = () => {
  return (
    <section
      id="services"
      className="services section"
      style={{ backgroundColor: "#34495e" }}
    >
      <div className="container section-title" data-aos="fade-up">
        <h2 style={{ color: "#fff" }}>Choose Us</h2>
        <p style={{ color: "#fff" }}>Why You Choose us</p>
      </div>

      <div className="container">
        <div className="row gy-4">
          {choose?.map((item) => (
            <div
              className="col-lg-4 col-md-3"
              data-aos="fade-up"
              data-aos-delay={400}
              key={item.label}
            >
              <div
                className="service-item position-relative"
                style={{
                  border: "5px solid #ffc451",
                }}
              >
                <div
                  className="icon"
                  style={{
                    backgroundColor: "transparent",
                    filter: "contrast(2)",
                  }}
                >
                  <img src={item.img} alt={item.img} />
                </div>
                <h3 style={{ color: "#000" }}>{item?.label}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default whyYouChooseUs;
