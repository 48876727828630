import Team1 from "../../assets/img/team/team-1.jpg";

const team = [
  {
    name: "Pranay Wadekar",
    designation: "Owner",
    description:
      "By Education Mr. Pranay is Computer Engineer. By passion and profession Pranay has deep knowledge and interest in global business. He also owns his Garments Manufacturing Company.",
    image: Team1,
  },
];

const KeyPerson = () => {
  return (
    <section id="team" className="team section" style={{ marginTop: "5rem" }}>
      <div className="container section-title" data-aos="fade-up">
        <h2>Team</h2>
        <p>our Team</p>
      </div>
      <div className="container">
        <div className="row gy-4">
          {team?.map((team) => (
            <div
              key={team?.description}
              className="col-lg-5 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={400}
              style={{
                border: "5px solid rgb(255, 196, 81)",
                paddingTop: "1rem",
                borderRadius: "0",
              }}
            >
              <div className="team-member">
                <div className="member-img">
                  <img
                    src={team.image}
                    className="img-fluid"
                    alt={team.image}
                  />
                  <div className="social">
                    <a href="https://twitter.com">
                      <i className="bi bi-twitter-x" />
                    </a>
                    <a href="https://twitter.com">
                      <i className="bi bi-facebook" />
                    </a>
                    <a href="https://twitter.com">
                      <i className="bi bi-instagram" />
                    </a>
                    <a href="https://twitter.com">
                      <i className="bi bi-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="member-info">
                <h4>{team?.name}</h4>
                <span>{team?.designation}</span>
                <p> {team?.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyPerson;
