import Ceramic_Stone_Products from "../../assets/img/what we export/Ceramic-Stone-Products.webp";
import Fruits_Vegetables from "../../assets/img/what we export/Fruits-Vegetables.webp";
import Garments from "../../assets/img/what we export/Garments.webp";
import Grocery_Products from "../../assets/img/what we export/Grocery-Products.webp";
import Handicraft from "../../assets/img/what we export/Handicraft.webp";
import Leather from "../../assets/img/what we export/Leather.webp";
import Spices from "../../assets/img/what we export/Spices-2.webp";
import copper_bottle_sub_category from "../../assets/img/what we export/copper-bottle-sub-category.webp";
import frozen_food from "../../assets/img/what we export/frozen-food.webp";
import furniture from "../../assets/img/what we export/furniture.webp";
import imitation from "../../assets/img/what we export/imitation.webp";
import packaging_paper_sub_category from "../../assets/img/what we export/packaging-paper-sub-category.webp";

const whatWeExport = () => {
  const data = [
    {
      title: "Fruits & Vegetables",
      description:
        "We are offering fresh and hygienic fruits and vegetables. Fruits and vegetables contain important vitamins, minerals & plant chemicals...",
      image: Fruits_Vegetables,
    },
    {
      title: "Spices Products",
      description:
        "A spice is a seed, fruit, root, bark, or other plant substance primarily used for flavoring or coloring food. Spices are distinguished from herbs…",
      image: Spices,
    },
    {
      title: "Grocery Products",
      description:
        "We are here to provide variety of grocery products to our valuable customer like dal, flours and pulses as per their requirements… ",
      image: Grocery_Products,
    },
    {
      title: "Namekeen & Frozen",
      description:
        "Indian salted Namkeen have earned the flavour of global customers due to their unique mix of sweet, salty, tangy and spicy flavours…",
      image: frozen_food,
    },
    {
      title: "Handicraft Products",
      description:
        "A handicraft, sometimes more precisely expressed as artisanal handicraft or handmade, is any of a wide variety of types of work where…",
      image: Handicraft,
    },
    {
      title: "Copper Products",
      description:
        "Copper is an imperative nutrient for body. It helps the body to form red blood cells, maintain healthy bones, blood vessels, nerves, immune function…",
      image: copper_bottle_sub_category,
    },
    {
      title: "Imitation Jewellery",
      description:
        "Artificial Jewellery, also known as costume jewellery, imitation jewellery or fashion jewellery is loved by one and all for its beauty and pocket friendly prices and looks like a real jewellery.",
      image: imitation,
    },
    {
      title: "Furniture",
      description:
        "f you’re facing an empty house or a big remodel, you’ve got big choices to make. Before you decide how to fill your home, learn about the options you have the types of furnitures.",
      image: furniture,
    },
    {
      title: "Leather Products",
      description:
        "Leather products are highly coveted for their classy look and feel. Be its shoes, bags or jackets, pure leather products can always be set apart from the synthetics and the faux leather.",
      image: Leather,
    },
    {
      title: "Garment Products",
      description:
        "Garments could be classified based on several aspects as there is no standard classification system available. However, the garments could be…",
      image: Garments,
    },
    {
      title: "Ceramic & Stone",
      description:
        "Ceramics are classified as inorganic and nonmetallic materials that are essential to our daily lifestyle. Ceramic and materials engineers are the…",
      image: Ceramic_Stone_Products,
    },
    {
      title: "Packaging Products",
      description:
        "OES Export Import Pvt ltd packaging Products are known for their quality, durability and the precision they come with. As a quality…",
      image: packaging_paper_sub_category,
    },
  ];

  return (
    <section id="about" className="about section">
      <div
        className="container section-title"
        data-aos="fade-up"
        style={{ paddingBottom: "2rem" }}
      >
        <h2>Export</h2>
        <p>What We Export</p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay={100}>
        <div className="row gy-4">
          {data?.map((item) => (
            <div className="col-lg-4 child-img-col" key={item?.title}>
              <div
                className="child-img-wrapper"
                style={{
                  border: "5px solid #ffc451",
                }}
              >
                <img
                  src={item?.image}
                  alt={item?.image}
                  className="child-img"
                />
                <h1
                  style={{
                    color: "#000",
                    marginTop: "1.5rem",
                    fontWeight: "bolder",
                    fontSize: "1.1rem",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {item?.title}
                </h1>
                <hr />
                <p style={{ fontWeight: "500", textAlign: "justify" }}>
                  {item?.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default whatWeExport;
