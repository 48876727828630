import { Carousel } from "react-responsive-carousel";
import One from "../../assets/img/slider/1.jpg";
import Two from "../../assets/img/slider/2.jpg";
import Three from "../../assets/img/slider/3.jpg";
import Four from "../../assets/img/slider/4.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import "./styles.css";

import { EffectCreative } from "swiper/modules";

const Slider = () => {
  const images = [One, Two, Three, Four];

  return (
    <div style={{ marginTop: "5rem", position: "relative" }}>
      <Carousel
        autoPlay={true}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
      >
        {images?.map((image) => (
          <div key={image}>
            <img src={image} alt={image} />
          </div>
        ))}
      </Carousel>

      {/* <Swiper
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            origin: "left center",
            translate: ["-5%", 0, -200],
            rotate: [0, 100, 0],
          },
          next: {
            origin: "right center",
            translate: ["5%", 0, -200],
            rotate: [0, -100, 0],
          },
        }}
        modules={[EffectCreative]}
        className="mySwiper6"
      >
        {images?.map((image) => (
          <div key={image}>
            <SwiperSlide style={{ width: "100%" }}>
              {" "}
              <img src={image} alt={image} />
            </SwiperSlide>
          </div>
        ))} */}
      {/* <SwiperSlide>Slide 1</SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide> */}
      {/* </Swiper> */}
    </div>
  );
};

export default Slider;
