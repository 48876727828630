import Image1 from "../../assets/img/services.jpg";

const HowWeWork = () => {
  const aboutItems = [
    "We find manufacturer as per buyer requirement.",
    "We negotiate and fix deal in suitable price range.",
    "We arrange transportation and handle full consignment.",
    "If buyer have supplier and manufacturer then we also provide shipment or transportation service.",
    "We can source products from multiple suppliers.",
  ];

  return (
    <section id="about" className="about section" style={{ marginTop: "6rem" }}>
      <div
        className="container section-title"
        data-aos="fade-up"
        style={{ paddingBottom: "2rem" }}
      >
        <h2>Our Work</h2>
        <p>How We Work</p>
      </div>
      <div className="container" data-aos="fade-up" data-aos-delay={100}>
        <div className="row gy-4">
          <h6>
            <em>
              <b>
                As a sourcing agent here is all the task, which we manage to
                fulfill requirements of our buyers and deliver their order
                according their needs in given timeline.
              </b>
            </em>
          </h6>
          <div
            className="col-lg-4 order-1 order-lg-2"
            style={{
              backgroundImage: `url(${Image1})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
            }}
          ></div>
          <div className="col-lg-8 order-2 order-lg-1 content">
            <ul>
              {aboutItems?.map((about) => (
                <li key={about}>
                  <i className="bi bi-check2-all" /> <span>{about}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
