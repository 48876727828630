import Export from "../../assets/img/export.png";

const WhatWeDo = () => {
  const expertise = [
    {
      title: "Export",
      description:
        "We are exporting best quality product on time you are at right place @one infinity international",
      icon: "bi bi-send",
    },
    {
      title: "Import Agent (for Foreign Client)",
      description:
        "We help foreign company to introduce his best quality product in india and help them to find local partners in india.",
      icon: "bi bi-people",
    },
    {
      title: "Sourcing Agent",
      description:
        "We are working as a local partner for our foreign client to source best quality product from India as per client requirement & specification.",
      icon: "bi bi-person-up",
    },
  ];

  return (
    <section
      id="services"
      className="services section"
      style={{
        backgroundImage: `url(${Export})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <div className="container section-title" data-aos="fade-up">
        <h2 style={{ color: "#fff" }}>Expertise</h2>
        <p style={{ color: "#fff" }}>What We Do</p>
      </div>

      <div className="container">
        <div className="row gy-4">
          {expertise?.map((item) => (
            <div
              className="col-lg-4 col-md-3"
              data-aos="fade-up"
              data-aos-delay={400}
              key={item?.title}
            >
              <div
                className="service-item position-relative"
                style={{
                  border: "5px solid #ffc451",
                }}
              >
                <div className="icon">
                  <i className={item.icon} />
                </div>
                <h3>{item?.title}</h3>
                <p> {item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
